<template>
    <v-row no-gutters>
        <v-col cols="3" class="my-4 mx-4">
            <v-card flat width="300" height="300">
                <v-row class="fill-height" no-gutters>
                    <v-col cols="12" align-self="center" class="text-h5" align="center"> Add New Client
                        <v-divider class="mt-3"></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Client Name" v-model="clientName" class="mx-3"></v-text-field>
                    </v-col>
                    <v-col align="center">
                        <v-btn outlined width="90%" @click="addClient()">
                            Add Client
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="8" class="my-4">
            <v-row>
                <v-col cols="3" v-for="(client, i) in clients" :key="i">
                    <v-card height="100" width="95%" hover @click="showClient(client.data().clientName)">
                        <v-row no-gutters class="fill-height">
                            <v-col cols="12" align-self="center" align="center">
                                <span class="text-uppercase font-weight-bold text-h6">{{ client.data().clientName}}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'superAdminHome',
    computed: {
        ...mapGetters(['clients', 'selectedClient'])
    },
    data() {
        return {
            clientName: ''
        }
    },
    methods: {
        addClient() {
            this.$store.dispatch('addClient', this.clientName).then(() => {
                this.clientName = ''
            })
        },
        showClient(clientName){
            if(this.selectedClient !== ''){
                this.$store.dispatch('detachListener')
            }
            this.$store.commit('setSelectedClient', clientName)
            this.$router.push('/clientManagement')
        }
    }
}
</script>