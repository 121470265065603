<template>
  <SuperAdminHome v-if="userProfile.data().userRole.toLowerCase() === 'super admin'"></SuperAdminHome>
  <adminHome v-else-if="(userProfile.data().userRole).toLowerCase() === 'admin'"></adminHome>
  <generalHome v-else></generalHome>
</template>

<script>
import adminHome from '@/views/admin/adminHome.vue';
import generalHome from '@/views/general/generalHome.vue';
import { mapGetters } from 'vuex';
import SuperAdminHome from './superAdmin/superAdminHome.vue';

export default {
  name: 'home',
  components: {
    adminHome,
    generalHome,
    SuperAdminHome
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  mounted() {
    //console.log('User Profile: ', this.userProfile)
  }
}
</script>