<template>
  <h1> Coming Soon </h1>
</template>

<script>
//import reports from "@/components/dashboard/admin/kioskAnalytics/reports.vue";
import { mapGetters } from "vuex";

export default {
  name: "adminHome",
  components: {
    //reports,
  },
  computed: {
    ...mapGetters(["allRegisteredUsers", "allRegisteredKiosks"]),
    usersCount() {
      let count = {
        availCount: 0,
        totalCount: 0
      };
      this.allRegisteredUsers.forEach(user => {
        count.totalCount = count.totalCount + 1
        if (user.data().onlineStatus === "Available") {
          // increase count here
          count.availCount = count.availCount + 1
        }
      })
      return count
    },
    kioskCount() {
      let count = {
        availCount: 0,
        totalCount: 0
      };
      this.allRegisteredKiosks.forEach(kiosk => {
        count.totalCount = count.totalCount + 1
        if (kiosk.data().onlineStatus === "Available") {
          // increase count here
          count.availCount = count.availCount + 1
        }
      })
      return count
    }
  }
};
</script>